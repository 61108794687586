/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//SWIPERSJS -- SLIDERS
@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import '~swiper/scss/autoplay';

@import "~@angular/cdk/overlay-prebuilt.css";
@import 'simple-keyboard/build/css/index.css';


@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


$main-color: var(--main-color);
$main-color-strong: var(--main-color-strong);

#chatbase-bubble-window{
  overflow: visible !important;
}

#chatbase-bubble-button{
  width: 65px !important;
  height: 65px !important;
  border-radius: 50% !important;
  right: 25px !important;
  bottom: 15px !important;
}

#chatIcon{
  width: 35px !important;
  height: 35px !important;
}

.alert-message.sc-ion-alert-ios {
  max-height: 250px;
}

.alert-message.sc-ion-alert-md {
  max-height: 240px;
  font-size: 16px;
}

.alert-title.sc-ion-alert-md {
  color: $main-color-strong;
  font-size: 22px;
  font-weight: bold;
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 4px;
  -webkit-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.headerLicense {
    height: 100%;
    display: flex;
    align-items: center;
    background: $main-color;
    width: 100%;
    justify-content: center;
}


* {
  font-family: 'Poppins';
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.swal2-styled.swal2-confirm{
  background: $main-color-strong !important;
  outline: none !important;
  border: none !important;
}

.swal2-styled:focus.swal2-confirm:focus{
  outline: none !important;
  border: none !important;

}

a[rel="noopener"] {
  display: none !important;
  border: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 12px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: $main-color !important; 
  --background: $main-color !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $main-color-strong !important;
  --background: $main-color !important; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-color-strong !important;
  --background: $main-color !important; 
}

.modal360{
  --width: 90% !important;
  --height: 90% !important; 
  --border-radius: 15px;
  --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.email-input-modal{
  --width: 90%;
  --height: 39%;
  --border-radius: 15px;
  --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.simple-keyboard .hg-button {
  height: 80px !important;
}

.ks-modal-gallery-backdrop {
  background: #000 !important;;
  opacity: 0.85 !important;;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.gm-style .gm-style-iw-tc::after {
  background: $main-color !important;
}

@supports (-webkit-touch-callout: none) {
  .gm-style-iw-c{
    background-color: $main-color !important;
  }
  .gm-style .gm-style-iw-d {
    background-color: $main-color !important;
    padding-right: 17px;
  }
}

@supports not (-webkit-touch-callout: none) {
  .gm-style-iw-c{
    background-color: $main-color !important;
  }
  .gm-style .gm-style-iw-d {
    background-color: $main-color !important;
    overflow: auto !important;
    padding-right: 18px  !important;
    padding-bottom: 10px !important;
  }
}


.gm-ui-hover-effect>span {
  background-color: #fff !important;
}

.mySwiperDetails {
  width: 100%;
}

.mySwiperDetails .swiper-wrapper {
  margin-bottom: 35px;
}

.mySwiperSlideDetails {
  position: relative;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiperSlideDetails img {
  display: block;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.mySwiperSlideDetails video {
  display: block;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.swiper-pagination-bullet-active{
  background: $main-color-strong;
}

.md,
.ios,
.wp {
  .custom-alert-danger {
    > div {
      background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
      text-align: center !important;
      zoom: 1;
    }

    .alert-head{
      text-align: center !important;
    }
  }
}

.modal-fullscreen{
    --height: 100%;
    --width: 100%;
}
.my-custom-class{
    --border-radius: 20px;
    --width: 90%;
    --height: 80%;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.next {
    background: #fff;
    font-size: 25px;
}

.back {
    background: #fff;
    font-size: 25px;
}

.close {
    background: #fff;
    font-size: 25px;
}

.alert-title{
  color: #000 !important;
}

.tour-overlay{
    background: #666;
    opacity: 0.5;
    z-index: 9996;
    min-height: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .tour-stop{
    position: relative;
    z-index: 9998;
    //outline: 10px solid white;
  }
  
  .tour-stop-bg{
    //background: white; 
    outline: 10px solid white;
    border-radius: 35px;
    position: fixed;
    z-index: 9997;
  }
  
  .tour-message{
    position: fixed;
    z-index: 9997;
    top: 0;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.26);
  }
  
  .tour-message .title{
    font-size: 1.75em;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .tour-message__controls{
    font-size: 1.5em;
  }

@media only screen and (max-width: 600px) {   

  .mySwiperSlideDetails img {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .mySwiperSlideDetails video {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }


.my-custom-class{
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
}
.container {
    width: 100% !important;
}
}
body {
    font-family: Poppins;
  }

  ion-calendar{

    .title{
      padding: 0 65px 0 65px !important; 
    }

    .week-title.light, .week-title.transparent{
      color: $main-color !important;
      font-weight: bold !important;
    }

    .title .switch-btn{
        color: #000 !important;
    }
    .title .back{
        border: 3px #000 solid !important;
        border-radius: 50% !important;
        overflow: auto !important;
        color: #000 !important;
        opacity: 1 !important;

    }

    .title .forward {
        border: 3px #000 solid !important;
        border-radius: 50% !important;
        overflow: auto !important;
        color: #000 !important;
        opacity: 1 !important;

    }

    .switch-btn{
        color: #000 !important;
        font-weight: bold;
    }

    .days-btn{
        font-weight: bold;
    }

    .days-btn.on-selected{
      background-color: $main-color !important;
    }

    .days.between > button{
      background-color: $main-color !important;
    }

    .days.endSelection > button {
      background-color: $main-color !important;
    }

    .days.startSelection::before {
      background-color: $main-color !important;
    }

    .days.endSelection::before {
      background-color: $main-color !important;
    }

    .week-title.light, .week-title.transparent {
      color: $main-color !important;
    }

    .days-btn.today > p{
      color: $main-color !important;
    }

    .days-btn.today.on-selected > p{
      color: #fff !important;
    }
    
}

.radio-icon{
    background: #fff !important;
}

body.swal2-height-auto {
    height: 100% !important;
}

.swal2-height-auto {
    height: 100% !important;
}

//Estilo Barra Busqueda Header
ion-searchbar {
    background: #fff !important;
    border-radius: 30px;
    overflow: hidden;
   .searchbar-input-container {
       background: #fff !important;
       box-shadow: none !important;
       input{
          background: #fff !important;
          box-shadow: none !important;
       }
   }
  }

ion-header {
    padding: 5px;
    background: $main-color;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; 
    display: flex; 
    justify-content: flex-end;
}

ion-footer {
  padding: 5px;
  background: $main-color;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; 
  display: flex; 
  justify-content: flex-end;
}

ion-toolbar {
  --background: $main-color;
}


.chooseStartEnd{
    --width: 35% !important;
    --height: 30% !important; 
    --border-radius: 15px;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .googleModal{
    --width: 35% !important;
    --height: 20% !important; 
    --border-radius: 15px;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .pdfModal{
    --width: 60% !important;
    --height: 80% !important; 
    --border-radius: 15px;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //--overflow-x: hidden;
  }

  .surveyModal{
    --width: 80% !important;
    --height: 80% !important; 
    --border-radius: 15px;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //--overflow-x: hidden;
  }

  .cropModal{
    --width: 500px !important;
    --height: 532px !important; 
    --border-radius: 15px;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //--overflow-x: hidden;
  }

  /*
  .ng2-pdf-viewer-container{
    overflow-x: false !important;
  }
  */

  .detailsModal{
    --border-radius: 15px !important;
    --width: 80% !important;
    --height: 80% !important; 
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media screen and (aspect-ratio: 9/16) {

    #chatbase-bubble-button{
      width: 92px !important;
      height: 92px !important;
      border-radius: 50% !important;
      right: 27px !important;
      bottom: 38px !important;
    }

    .surveyModal{
      --width: 80% !important;
      --height: 50% !important; 
      --border-radius: 15px;
      --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      //--overflow-x: hidden;
    }
    
    #chatIcon{
      width: 50px !important;
      height: 50px !important;
    }


    .mySwiperSlideDetails img {
      display: block;
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
    
    .mySwiperSlideDetails video {
      display: block;
      width: 100%;
      height: 500px;
      object-fit: cover;
    }

    .pdfModal{
      --width: 90% !important;
      --height: 80% !important; 
      --border-radius: 15px;
      --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      //--overflow-x: hidden;
    }

    .chooseStartEnd{
        --height: 15% !important;
        transform: scale(1.5);
      }

      .googleModal{
        --height: 15% !important;
        --width: 50% !important;
        //transform: scale(1.5);
      }

      .detailsModal{
        --width: 80% !important;
        --height: 50% !important; 
        --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .md,
    .ios,
    .wp {
      .custom-alert-danger {
        > div {
          background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
          text-align: center !important;
          zoom: 2;
        }
  
        .alert-head{
          text-align: center !important;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {

    .surveyModal{
      --width: 90% !important;
      --height: 90% !important; 
      --border-radius: 15px;
      --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      //--overflow-x: hidden;
    }

    .pdfModal{
      --width: 95% !important;
      --height: 95% !important; 
      --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .chooseStartEnd{
      --width: 95% !important;
      --height: 40% !important;
      --border-radius: 15px;
      --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .googleModal{
      --width: 95% !important;
      --height: 23% !important; 
      --border-radius: 15px;
      --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .detailsModal{
        --width: 90% !important;
        --height: 90% !important; 
        --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}